import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/auth/authSlice';
import { logoutFromServer } from '../../api/api';

const TopNavbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
          await logoutFromServer(); // Call the server to log out
          dispatch(logout());       // Update Redux state to log out
          navigate('/');       // Redirect to login after logout
        } catch (error) {
          console.error('Error logging out:', error);
        }
      };

    
    return (
    <div className="top-navbar">
        <h1>Admin Dashboard</h1>
        {/* Add any global actions or notifications here */}
        <button onClick={handleLogout} >
                Logout
        </button>
    </div>
    );
}

export default TopNavbar;