import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCategories } from "../../redux/categories/categoriesSlice";
import { fetchProducts } from "../../redux/products/productsSlice";
import styles from "./CategorySelect.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CategorySelect = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { categories } = useSelector((state) => state.categories);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null)
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    // Detect screen size changes
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1024);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCategoryId !== '') {
            const selectedCategory = categories.find(category => category.id === selectedCategoryId);

            if (selectedCategory) {
                //dispatch(fetchProducts(selectedCategoryId));
                navigate(`/category/${selectedCategory.name}`);
                setSelectedCategoryId('');
            }
        }
    }, [selectedCategoryId, categories, dispatch, navigate]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);  // Close the dropdown
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCategorySelect = (category) => {
        setIsOpen(false); // Close the dropdown after selection
        setSelectedCategoryId(category.id);
    };

    const capitalizeEachWord = (string) => {
        return string
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className={styles.navbarItem} ref={dropdownRef}>
            <button className={styles.dropdownLabel} onClick={toggleDropdown}>
                Categories
            </button>

            {isOpen && (
                isMobile ? (
                    <div className={styles.dropdownOverlay}>
                        <ul className={styles.dropdownMenu}>
                            {categories.map((category) => (
                                <li
                                    key={category.id}
                                    className={styles.dropdownItem}
                                    onClick={() => handleCategorySelect(category)}
                                >
                                    {capitalizeEachWord(category.name)}
                                </li>
                            ))}
                        </ul>
                        <button className={styles.closeOverlay} onClick={() => setIsOpen(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                ) : (
                    <ul className={styles.dropdownMenu}>
                        {categories.map((category) => (
                            <li
                                key={category.id}
                                className={styles.dropdownItem}
                                onClick={() => handleCategorySelect(category)}
                            >
                                {capitalizeEachWord(category.name)}
                            </li>
                        ))}
                    </ul>
                )
            )}
        </div>
    );
};

export default CategorySelect;