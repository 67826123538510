import React, {useEffect, useRef, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import CategorySelect from '../CategorySelect/CategorySelect';
import styles from './NavBar.module.css'; // Optional: Style for the NavBar
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const NavBar = ({ isLoggedIn, onLogout, cartItemCount }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const searchRef = useRef();

  const handleClick = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setIsActive(false);
      console.log(isActive);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect (() => {
    setIsActive(false);
  }, [location]);

  const handleToggle = () => {
    setIsActive(!isActive);
    console.log(isActive);
  };

  return (
    <nav className={styles.navbar} ref={searchRef}>
      <div className={styles.topRow}>
        <div className={styles.navbarLogo}>
          <Link to="/">KMI</Link> {/* Brand or Home link */}
        </div>

        <div className={styles.navSearch} >
          <SearchBar />
        </div>
      
        <div className={styles.hamburger} onClick={handleToggle} aria-label="toggle-navigation">
          <FontAwesomeIcon icon={faBars} />
        </div>

        <ul className={`${styles.navbarLinks} ${isActive ? styles.active : ''}`} >
          <li>
            <Link to="/cart">
              Cart {(isLoggedIn && cartItemCount > 0) && <span>({cartItemCount})</span>}
            </Link>
          </li>
          {isLoggedIn ? (
            <>
              <li>
                <Link to="/profile">My Account</Link>
              </li>
              
            </>
          ) : (
            <>
              <li>
                <Link to="/login">Login</Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={styles.bottomRow}>
        {/*<ul className={styles.navbarLinks}>*/}
          <div>
            <CategorySelect />
          </div>
          <div className={styles.productsLink}>
            <Link to="/products">Products</Link>
          </div>
          {isLoggedIn ? (
            <>
              <div>
                  <Link to="/orders">Orders</Link>
              </div>
              <div>
                  <button onClick={onLogout} className={styles.logoutButton}>
                    Logout
                  </button>
              </div>
            </>
          ) : (
            
              <div>
                <Link to="/register">Register</Link>
              </div>
              
            
          )}
          
        {/*</ul>*/}
      </div>
    </nav>
  );
};

export default NavBar;