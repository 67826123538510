import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchOrders } from '../../redux/orders/ordersSlice';
import OrderCard from '../../components/OrderCard/OrderCard';
import withSessionCheck from '../../components/WithSessionCheck/WithSessionCheck';
import styles from './OrdersPage.module.css';
const OrdersPage = () => {
    const dispatch = useDispatch();
    const { orders, loading, error } = useSelector((state) => state.orders);

    useEffect(() => {
        dispatch(fetchOrders());
    }, []);

    if (loading) return <p>Loading...</p>;
    if (orders.length === 0) return <p>No orders found.</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles.orderCard} >
            <h1>Your Orders</h1>
            {orders.map((order) => (
                <OrderCard order={order} />
            ))}
        </div>
    );
};

export default withSessionCheck(OrdersPage);