import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import API from '../../../api/api';
import styles from './UserManagement.module.css';

const UserManagement = () => {
    const [pendingUsers, setPendingUsers] = useState([]);
    const [approvedUsers, setApprovedUsers] = useState([]);
    const [showPending, setShowPending] = useState(true); // State to toggle view

    const fetchUsers = async () => {
            try {
                const pendingResponse = await API.get('/api/admin/pending-users');
                console.log('pending_users response: ', pendingResponse);
                setPendingUsers(pendingResponse.data.data);

                const approvedResponse = await API.get('/api/admin/approved-users');
                setApprovedUsers(approvedResponse.data.data);
            } catch (error) {
                console.error('Error fetching pending users:', error);
            }
        };

    // Fetch the list of pending users
    useEffect(() => {
        fetchUsers();
    }, []);

    const handleApprove = async (userId) => {
        // Handle user approval logic here
        try {
            await API.post(`/api/admin/approve-user/${userId}`);
            setPendingUsers((prev) => prev.filter(user => user.id !== userId)); // Update state after approving
            fetchUsers();
        } catch (error) {
            console.error('Error approving user:', error);
        }
    };

    const handleDelete = async (userId) => {
        // Handle user deletion logic here
        try {
            await API.delete(`/api/admin/delete-user/${userId}`);
            setPendingUsers((prev) => prev.filter(user => user.id !== userId)); // Update state after deletion
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    return (
        <div className={styles.userManagementContainer}>
            <h2>User Management</h2>

            {/* Toggle Buttons */}
            <div className={styles.toggleButtons}>
                <button
                    onClick={() => setShowPending(true)}
                    className={showPending ? styles.activeButton : styles.inactiveButton}
                >
                    Pending Users
                </button>
                <button
                    onClick={() => setShowPending(false)}
                    className={!showPending ? styles.activeButton : styles.inactiveButton}
                >
                    Approved Users
                </button>
            </div>

            <div className={styles.tableContainer}>
                <table className={styles.userTable}>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Town/City</th>
                            <th>LastUpdatedAt</th>
                            <th>Sales Tax Id #:</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(showPending ? pendingUsers : approvedUsers).map((user) => (
                            <tr key={user.id}>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td>{user.store_name}</td>
                                <td>{user.city}</td>
                                <td>{new Date(user.updated_at).toLocaleString()}</td>
                                <td>{user.sales_tax_number}</td>
                                <td className={styles.actionButtons}>
                                    {showPending && <button onClick={() => handleApprove(user.id)} className={styles.approveButton}>Approve</button>}
                                    <button onClick={() => handleDelete(user.id)} className={styles.deleteButton}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserManagement;