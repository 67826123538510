import React from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import TopNavbar from '../components/TopNavbar';
import UserManagement from '../components/UserManagement/UserManagement';
import OrderManagement from '../components/OrderManagement';
import ProductManagement from '../components/ProductManagement';
import CategoryManagement from '../components/CategoryManagement';
import styles from './AdminDashboard.module.css';

const AdminDashboard = () => {
    const [activeSection, setActiveSection] = React.useState('UserManagement');

    const renderSection = () => {
        switch (activeSection) {
            case 'UserManagement':
                return <UserManagement />;
            case 'OrderManagement':
                return <OrderManagement />;
            case 'ProductManagement':
                return <ProductManagement />;
            case 'CategoryManagement':
                return <CategoryManagement />;
            default:
                return <UserManagement />;
        }
    };

    return (
        <div className={styles.dashboardContainer}>
            <Sidebar setActiveSection={setActiveSection} activeSection={activeSection} />
            <div className={styles.mainContent}>
                <TopNavbar className={styles.topNavBar} />
                <div className={styles.content}>
                    {renderSection()}
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;