import React from 'react';
import styles from './Sidebar.module.css';

const Sidebar = ({ setActiveSection, activeSection }) => (
    <div className={styles.sidebarContainer}>
        <h2>Admin Dashboard</h2>
        <ul className={styles.sidebarLinks}>
            <li 
                onClick={() => setActiveSection('UserManagement')}
                className={activeSection === 'UserManagement' ? styles.active : ''}
            >
                User Management
            </li>
            <li 
                onClick={() => setActiveSection('OrderManagement')}
                className={activeSection === 'OrderManagement' ? styles.active : ''}
            >
                Order Management
            </li>
            <li 
                onClick={() => setActiveSection('ProductManagement')}
                className={activeSection === 'ProductManagement' ? styles.active : ''}
            >
                Product Management
            </li>
            <li 
                onClick={() => setActiveSection('CategoryManagement')}
                className={activeSection === 'CategoryManagement' ? styles.active : ''}
            >
                Category Management
            </li>
        </ul>
    </div>
);

export default Sidebar;