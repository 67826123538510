import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { searchProducts } from "../../redux/products/productsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import styles from './SearchBar.module.css';

const SearchBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const inputRef = useRef();

    const [searchTerm, setSearchTerm] = useState('');
    const [prevPath, setPrevPath] = useState(location.pathname);

    useEffect(() => {
      // Check if the user navigates away from `/search/...` to a different path
      if (
        prevPath.startsWith('/search/') &&
        !location.pathname.startsWith('/search/')
      ) {
        // Clear search term if navigating away from any search route
        setSearchTerm('');
      }
  
      // Update previous path state to the current location path
      setPrevPath(location.pathname);
    }, [location.pathname, prevPath]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            dispatch(searchProducts({page: 1, limit: 50, searchTerm: searchTerm}));
            navigate(`search/${searchTerm}`);
        } 
        inputRef.current.blur(); // Remove focus
    };

    return (
        <form onSubmit={handleSearch} className={styles.searchContainer} >
            <input
                type="text"
                className={styles.searchInput}
                ref={inputRef}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
            />
            <button type="submit" className={styles.searchButton} >
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    );
};

export default SearchBar;