import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchProducts, searchProducts } from '../../redux/products/productsSlice';  // Path might differ
import { fetchCategories } from '../../redux/categories/categoriesSlice';
import ProductsGrid from '../../components/ProductsGrid/ProductsGrid';
import styles from './CategoryPage.module.css';  // Assuming you will add some styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const CategoryPage = () => {
    const { categoryName } = useParams();
    const uriCategory = decodeURIComponent(categoryName);
    const category = capitalizeEachWord(uriCategory);
    const dispatch = useDispatch();
    const { products, currentPage, totalPages, loading, error } = useSelector((state) => state.products);

    const [page, setPage] = useState(1);

    function capitalizeEachWord(string) {
        return string
            .split(' ') // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join the words back into a single string
    }

    useEffect(() => {
        console.log(categoryName);
        setPage(1);
    }, [categoryName]);

    // Fetch products when the selected category changes
    useEffect(() => {
        if (category === 'Christmas') {
            dispatch(fetchProducts({page, limit: 50, category: 27}));
        } else if (category === 'Fall' || category === 'Fall & Halloween') {
            dispatch(fetchProducts({page, limit: 50, category: 22}));
        } else if (category === 'Bells & Stars') {
            dispatch(fetchProducts({page, limit: 50, category: 2}));
        } else if (category === 'Berries & Others') {
            dispatch(fetchProducts({page, limit: 50, category: 8}));
        } else if (category === 'Easter') {
            dispatch(fetchProducts({page, limit: 50, category: 5}));
        } else if (category === 'Rice Berries') {
            dispatch(fetchProducts({page, limit: 50, category: 3}));
        } else if (category === 'Picks & Fillers') {
            dispatch(fetchProducts({page, limit: 50, category: 1}));
        } else if (category === 'Berries') {
            dispatch(fetchProducts({page, limit: 50, category: 4}));
        } else if (category === 'Candle Rings') {
            dispatch(fetchProducts({page, limit: 50, category: 6}));
        } else if (category === 'Mixed Berries') {
            dispatch(fetchProducts({page, limit: 50, category: 7}));
        } else if (category === 'Floral Series') {
            dispatch(fetchProducts({page, limit: 50, category: 10}));
        } else if (category === 'Grapevine') {
            dispatch(fetchProducts({page, limit: 50, category: 11}));
        } else if (category === 'Picks & Fillers (sub: Berries)') {
            dispatch(fetchProducts({page, limit: 50, category: 12}));
        } else if (category === 'Picks & Fillers (sub: Floral)') {
            dispatch(fetchProducts({page, limit: 50, category: 13}));
        } else if (category === 'Series') {
            dispatch(fetchProducts({page, limit: 50, category: 14}));
        } else if (category === 'Burlap') {
            dispatch(fetchProducts({page, limit: 50, category: 15}));
        } else if (category === 'Button') {
            dispatch(fetchProducts({page, limit: 50, category: 16}));
        } else if (category === 'Floral') {
            dispatch(fetchProducts({page, limit: 50, category: 17}));
        } else if (category === 'Wooden-twine Stars & Fences') {
            dispatch(fetchProducts({page, limit: 50, category: 18}));
        } else if (category === 'Americana') {
            dispatch(fetchProducts({page, limit: 50, category: 19}));
        } else if (category === 'Picks & Fillersrice Berries') {
            dispatch(fetchProducts({page, limit: 50, category: 20}));
        } else if (category === 'Succulent') {
            dispatch(fetchProducts({page, limit: 50, category: 21}));
        } else if (category === 'Fall & Limited Suppy') {
            dispatch(fetchProducts({page, limit: 50, category: 23}));
        } else if (category === 'Christmas & Limited Suppy') {
            dispatch(fetchProducts({page, limit: 50, category: 24}));
        } else if (category === 'Led Lights') {
            dispatch(fetchProducts({page, limit: 50, category: 25}));
        } else if (category === 'Metals & Lanterns') {
            dispatch(fetchProducts({page, limit: 50, category: 26}));
        } 
    }, [category, page]);

    const handleNextPage = () => {
        console.log('totalPages: ', totalPages);
        if (page < totalPages) {
            setPage(page + 1);
            console.log('page', page);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles.productsPage}>
            <h1>{capitalizeEachWord(category)}</h1>

            <ProductsGrid products={products} />

            <div className={styles.pageButtonWrapper} >
                <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
            </div>
        </div>
    );
};

export default CategoryPage;