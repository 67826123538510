import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import OrderCard from '../../components/OrderCard/OrderCard';
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import withSessionCheck from '../../components/WithSessionCheck/WithSessionCheck';
import API from '../../api/api';
import styles from './CheckoutPage.module.css';

// Load Stripe
const stripePromise = loadStripe('pk_test_51Pv2hUAh0rCB3FEGAT2O3Vk14zVPoBmbS8huPvUtdSVCplQCaF6k04MgFgh20YaqzLqDk6uhxEDTmTI5pQbClF3t000EGSAmCm');

const CheckoutPage = () => {
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const cartId = useSelector((state) => state.cart.cartId);
  const cart = useSelector((state) => state.cart); 
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Call your backend to create the PaymentIntent and get the ID
    const createPaymentIntent = async () => {
      const response = await API.post(`/api/cart/${cartId}/checkout`);
      const data = await response.data;
      setPaymentIntentId(data.paymentIntentId);
      setClientSecret(data.clientSecret);
      setTotalAmount(data.total);
    };

    createPaymentIntent();
  }, [cartId]);

  console.log(typeof cartId);

  const handlePaymentSuccess = (paymentIntent) => {
    console.log('Payment authorized, PaymentIntent:', paymentIntent);
    // Additional logic, e.g., updating the UI or proceeding to the next step
  };

  return (
    <div className={styles.checkoutContainer}>
      <h2>Checkout</h2>

      {/* Display Cart Items */}
      <div className={styles.cartSummary}>
        <h3>Your Items</h3>
        <OrderCard order={cart} />
        <h3>Total: ${totalAmount.toFixed(2)}</h3>
      </div>

      <Elements stripe={stripePromise}>
        {paymentIntentId ? (
          <CheckoutForm 
            paymentIntentId={paymentIntentId} 
            onPaymentSuccess={handlePaymentSuccess}
            totalAmount={totalAmount} 
            cartId={cartId}
            clientSecret={clientSecret}
          />
        ) : (
          <p>Loading payment details...</p>
        )}
      </Elements>
    </div>
  );
};

export default withSessionCheck(CheckoutPage);