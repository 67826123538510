// api.js
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const registerUser = async (userData) => {
    try {
        const response = await API.post('/api/users/register', userData); 
        console.log(response.data);
      } catch (error) {
        throw error.response || error; 
      }
};

export const logoutFromServer = async () => {
    try {
      return await API.post('/api/users/logout'); 
    } catch (error) {
      console.error('Error logging out from server:', error);
      throw error; 
    }
};

export default API;