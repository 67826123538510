import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { addToCart } from '../../redux/currentCart/currentCart';
import styles from './OrderCard.module.css'; 



const OrderCard = ({ order }) => {
    const createdAt = order.createdAt;
    const total = order.orderTotal ? order.orderTotal : order.totalAmount;
    const status = order.orderStatus;
    const orderId = order.orderId ? order.orderId : order.cartId;
    console.log('Total:', total, 'Type:', typeof total);
    const formattedTotal = (total ? Number(total) : 0).toFixed(2);

    return (
        <div className={styles.cardOuter} >
            <div className={styles.orderInfo} >
                {createdAt &&  <div className={styles.infoCell} >
                    <h5>ORDER PLACED</h5>
                    <h4>{new Date(createdAt).toLocaleDateString()}</h4>
                </div>}

                <div className={styles.infoCell} >
                    <h5>TOTAL</h5>
                    <h4>${formattedTotal}</h4>
                </div>

                {status && <div className={styles.infoCell} >
                    <h5>STATUS</h5>
                    <h4>{status}</h4>
                </div>}

                <div className={styles.orderDetails} >
                    {order.orderId ? (<h5>ORDER ID</h5>) : (<h5>CART ID</h5>)}
                    <h4>{orderId}</h4>
                </div>
            </div>

            {order.items.map((item) => {
                const mainImage = item.itemImages && item.itemImages.length > 0 ? item.itemImages[0] : null;
                console.log(mainImage);

                return (<div>
                    <Link to={`/products/${item.productId}`} className={styles.itemWrap} > 
                    <div className={styles.overlay} >
                    <img src={mainImage ? mainImage : 'https://user-images.githubusercontent.com/5671907/174857173-c3351777-14f1-4e12-bcb4-f46693f9dbe0.png'} />
                    <div className={styles.itemDetails} >
                        <h4>{item.productName}</h4>
                        <h5>{item.itemNumber}</h5>
                    </div>
                    </div>
                    </Link>
                </div>);
            })}

        </div>
    );
};

export default OrderCard;