// src/components/Login.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/auth/authSlice.js';
import { fetchCart } from '../../redux/currentCart/currentCart.js';
import { useNavigate } from 'react-router-dom';
import styles from './LoginPage.module.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, error, isLoggedIn } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const result = await dispatch(loginUser({ email, password })).unwrap();
        if (result.role === 'admin') {
            navigate('/admin-dashboard');
        } else {
            dispatch(fetchCart());
            navigate('/profile');
        }
    } catch (err) {
        console.error('Login failed:', err);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display any error */}
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <div className={styles.formField}>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.formField}>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      {isLoggedIn && <p>Login successful!</p>}
    </div>
  );
};

export default Login;