import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
    const navigate = useNavigate();
    const { user, loading, error, isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        // Wait for loading to complete before checking authorization
        if (!loading) {
            console.log('user: ', user, 'isLoggedIn: ', isLoggedIn);
            if (isLoggedIn == false) {
                // If not logged in, redirect to home page
                navigate('/');
            } else if (user && user.role !== 'admin') {
                console.log('user: ', user);
                // If not an admin, redirect to "not authorized" page
                navigate('/not-authorized');
            } else if (!user) {
                console.log('waiting on user...')
            } /*else if (isLoggedIn == true) {
                console.log('User is logged in');
            }*/ else {
                console.log('still loading...');
            }
        }
    }, [loading, isLoggedIn, user, navigate]);

    console.log(user);

    // If still loading, don't render anything yet
    if (loading) {
        return null; // Or you could return a loading spinner here
    }

    if (user && user.role !== 'admin') {
        // Redirect non-admin users to a different page, like the login page or a "not authorized" page
        return <Navigate to="/not-authorized" />;
    }

    // If the user is an admin, render the children components
    if (user && user.role == 'admin') {
        return children;
    }

    // If loading is complete, user is logged in, and has the admin role, render children
    /*if (isLoggedIn && user && user.role === 'admin') {
        return children;
    }*/

    // If user is not logged in or not an admin, redirect to login or another page
    //return <Navigate to="/" />;
    
    /*if (!user) {
        // Redirect to login or another page when user is null
        return <Navigate to="/" />;
    }

    // Check if the user is logged in and has the 'admin' role
    if (user && user.role !== 'admin') {
        // Redirect non-admin users to a different page, like the login page or a "not authorized" page
        return <Navigate to="/not-authorized" />;
    }

    // If the user is an admin, render the children components
    return children; */
};

export default AdminRoute;