import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/currentCart/currentCart';
import styles from './AddToCart.module.css'; 

const AddToCart = ({ product }) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState();

    useEffect(() => {
        if (product) {
            setQuantity(product.qty1);  // Set the initial quantity to qty1
        }
    }, [product]);

    const handleBlur = () => {
        if (product && quantity < product.qty1) {
            setQuantity(product.qty1);  
        }
    };

    const handleAddToCart = () => {
        const finalQuantity = Math.max(quantity, product.qty1);
        dispatch(addToCart({ productId: product.id, quantity: finalQuantity }));
    };

    return (
        <div className={styles.qtyAdd} >
            <label htmlFor="quantity">Select Quantity:</label>
            {product.qty1 && (
                <input
                    type="number"
                    id="quantity"
                    value={quantity}
                    min={product.qty1} // Minimum quantity is qty1
                    onChange={(e) => setQuantity(Number(e.target.value), product.qty1)}  // Ensure the value is not below qty1
                    onBlur={handleBlur}
                />
            )}
            <button onClick={handleAddToCart}>Add to Cart</button>
        </div>
    );
};

export default AddToCart;