import React from "react";
import { Link } from "react-router-dom";
import AddToCart from "../AddToCart/AddToCart";
import styles from "./ProductsGrid.module.css";

const ProductsGrid = ({ products }) => {

    return (
        <div className={styles.productsGrid}>
            {products.map((product) => {
                const mainImage = product?.images?.find(img => img.image_type === 'closeup') || 
                               product?.images?.find(img => img.image_type === 'main') ||
                               (product?.images ? product.images[0] : null);                
                return (
                    <div key={product.id} className={styles.productCard}>
                    <Link to={`/products/${product.id}`}>
                        <div className={styles.imageWrapper}>
                            <img 
                                src={mainImage ? mainImage.image_url : 'https://user-images.githubusercontent.com/5671907/174857173-c3351777-14f1-4e12-bcb4-f46693f9dbe0.png'} 
                                alt={product.item_number} 
                            />
                        </div>
                        <h4>{product.description}</h4>
                    </Link>
                    <p>{product.item_number}</p>
                    <p>${product.price1}</p>
                    {/*console.log(product)*/}
                    {<AddToCart product={product} />}
                    </div>
                );
            })}
        </div>
    );
};

export default ProductsGrid;