import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProducts, searchProducts } from '../../redux/products/productsSlice';  // Path might differ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import ProductsGrid from '../../components/ProductsGrid/ProductsGrid';
import styles from './ProductsPage.module.css';  // Assuming you will add some styles


const ProductsPage = () => {
    const dispatch = useDispatch();
    const { products, currentPage, totalPages, loading, error } = useSelector((state) => state.products);

    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(fetchProducts({ page, limit: 50 }));
    }, [dispatch, page]);

    const handleNextPage = () => {
        console.log('totalPages: ', totalPages);
        if (page < totalPages) {
            setPage(page + 1);
            console.log('page', page);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles.productsPage}>
            <div className={styles.searchWrapper} >
                <h1>Our Products</h1>
                <div className={styles.pageButtonWrapper} >
                    <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
                </div>
            </div>
            <ProductsGrid products={products} />
            <div className={styles.pageButtonWrapper} >
                <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
            </div>
        </div>
    );
};

export default ProductsPage;