import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProducts, searchProducts } from '../../redux/products/productsSlice';  // Path might differ
import { fetchCategories } from '../../redux/categories/categoriesSlice';
import ProductsGrid from '../../components/ProductsGrid/ProductsGrid';
import styles from './SearchResults.module.css';  // Assuming you will add some styles

const SearchResults = () => {
    const { searchQuery } = useParams();
    const dispatch = useDispatch();
    const { products, currentPage, totalPages, loading, error } = useSelector((state) => state.products);

    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(searchProducts({ page, limit: 50, searchTerm: searchQuery }));
    }, [dispatch, page]);

    const handleNextPage = () => {
        console.log('totalPages: ', totalPages);
        if (page < totalPages) {
            setPage(page + 1);
            console.log('page', page);
        }
    };

    const handlePrevPage = () => {
        console.log(searchQuery);
        if (page > 1) {
            setPage(page - 1);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles.productsPage}>
            <div className={styles.searchWrapper} >
                <h1>Results for {searchQuery}</h1>
                <div className={styles.pageButtonWrapper} >
                    <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
                </div>
            </div>
            <ProductsGrid products={products} />
        </div>
    );
};

export default SearchResults;